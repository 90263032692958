import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Overlay } from 'components/common/Overlay/Overlay';
import { SearchInput } from 'components/common/SearchInput/SearchInput';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useSearchParamsPagination } from 'hooks/useSearchParamsPagination';
import { BillingService } from 'services/billing';
import { PatientService } from 'services/patient';
import { PatientNS } from 'services/patient/types';

const gender = {
  M: 'Male',
  F: 'Female',
  blank: '',
};

type ActivePatientsProps = {
  showArchivedPatients?: boolean;
};
export function ActivePatients({
  showArchivedPatients = false,
}: ActivePatientsProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [archivePatient, setArchivePatient] = useState({
    state: false,
    id: '',
    hasSubscription: false,
  });
  const [search, setSearch] = useState<string>('');
  const [showBiggerImage, setShowBiggerImage] = useState({
    state: false,
    data: '',
  });

  const { page, setPage } = useSearchParamsPagination({
    key: showArchivedPatients ? 'archived_pt_page' : 'active_pt_page',
  });

  const cancelSubscriptionMutation = useMutationWithToast(
    BillingService.cancelSubscription
  );
  const activatePatientMutation = useMutationWithToast(
    PatientService.switchUserActive
  );
  const getPatientsQuery = useQuery(
    QueryKeys.Patients.listing({
      page,
      search,
      archived: showArchivedPatients ? 'yes' : 'no',
    }),
    () =>
      showArchivedPatients
        ? PatientService.getPatients({
            page,
            search,
            archived: showArchivedPatients,
          })
        : PatientService.getPatientsV2({
            page,
            search,
            archived: showArchivedPatients,
          }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleRowClick = (item: PatientNS.PatientInfo) => {
    navigate(`../${item?.id}/overview`);
  };
  useEffect(() => {
    if (!search) return;
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  function refetchAndClose() {
    queryClient.refetchQueries(
      QueryKeys.Patients.listing({
        page,
        search,
        archived: 'no',
      })
    );
    setArchivePatient({ state: false, id: '', hasSubscription: false });
  }

  const handleArchivePatient = () => {
    if (archivePatient.hasSubscription) {
      cancelSubscriptionMutation.mutate(archivePatient.id, {
        onSuccess() {
          refetchAndClose();
        },
      });
    } else {
      activatePatientMutation.mutate(
        { id: archivePatient.id, isActive: false },
        {
          onSuccess() {
            refetchAndClose();
          },
        }
      );
    }
  };

  const handleActivatePatient = () => {
    activatePatientMutation.mutate(
      { id: archivePatient.id, isActive: true },
      {
        onSuccess() {
          queryClient.refetchQueries(
            QueryKeys.Patients.listing({
              page,
              search,
              archived: 'yes',
            })
          );
          setArchivePatient({ state: false, id: '', hasSubscription: false });
        },
      }
    );
  };

  return (
    <div className="col-span-6 ">
      <span className="mb-2 flex  items-center justify-end text-base ">
        <SearchInput
          onSearch={(value) => {
            setSearch(value);
          }}
        />
      </span>

      <Table
        loading={getPatientsQuery?.isLoading || getPatientsQuery.isRefetching}
        rowsData={getPatientsQuery?.data?.data?.items || []}
        columns={[
          {
            title: 'ID',
            render: (rowData) => rowData?.id,
          },
          {
            title: 'Name',
            render: (rowData) => (
              <div className=" flex items-center gap-2">
                <Avatar
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowBiggerImage({
                      state: !!rowData?.pictureUrl,
                      data: rowData?.pictureUrl,
                    });
                  }}
                  // data-tooltip-id={rowData?.id}
                  sizeClass="h-8 w-8 "
                  imgUrl={rowData?.pictureUrl}
                />

                <Typography>{rowData?.name}</Typography>
              </div>
            ),
          },
          {
            title: 'Gender',
            render: (rowData) => gender?.[rowData?.patientDetails?.gender],
          },
          {
            title: 'Completed Visits',
            render: (rowData) => rowData?.patientCompletedVisits || 0,
            classNames: 'text-center  ',
            // skeletonClassNames: 'flex justify-center',
          },
          {
            title: '',
            render: (rowData) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setArchivePatient({
                    state: true,
                    id: rowData?.id,
                    hasSubscription: rowData.patientCompletedVisits > 0,
                  });
                }}
                variant="outlined"
                size="small"
                className="">
                {showArchivedPatients ? 'Retrieve' : 'Archive'}
              </Button>
            ),
            classNames: 'text-center  ',
            skeletonClassNames: 'flex justify-center',
          },
        ]}
        onRowClick={handleRowClick}
        noDataMessage={
          search
            ? 'No patient match this search criteria'
            : showArchivedPatients
            ? 'You currently have no archived patients'
            : 'You currently have no patients registered'
        }
        pagination
        onPageChange={setPage}
        page={page}
        totalRecords={getPatientsQuery?.data?.data?.total || 0}
        recordsPerPage={10}
      />
      <Overlay
        onClose={() => {
          setShowBiggerImage({
            state: false,
            data: '',
          });
        }}
        className="!flex !items-center !justify-center"
        open={showBiggerImage.state}>
        {' '}
        <Avatar sizeClass="h-96 w-96 " imgUrl={showBiggerImage.data} />
      </Overlay>
      <ConfirmationModal
        confirmLoading={
          cancelSubscriptionMutation.isLoading ||
          activatePatientMutation.isLoading
        }
        handleConfirm={
          showArchivedPatients ? handleActivatePatient : handleArchivePatient
        }
        onCancel={() => {
          setArchivePatient({ state: false, id: '', hasSubscription: false });
        }}
        confirmText={showArchivedPatients ? 'Retrieve' : 'Archive'}
        semiBoldTitle
        isModalOpen={archivePatient.state}
        title={
          showArchivedPatients
            ? 'Are you sure you want to retrieve this patient?'
            : 'Are you sure you want to archive this patient? Archiving will move this patient from the active patient list to the archived patient list. Please note that if this patient has an active subscription, archiving them will result in the cancellation of their subscription.'
        }
        closeModal={() => {
          //
        }}
      />
    </div>
  );
}
