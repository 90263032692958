import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';

export const useProgesteroneController = () => {
  const navigate = useNavigate();
  const ProgesteroneDetails = femaleHormoneRxTypes?.progesterone;
  const [strength, setStrength] = useState(0);
  const [maximumDose, setMaximumDose] = useState(0);

  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    ProgesteroneDetails.deliveryMethod
  );
  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(30);
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');

  function handleDose(val: number, type?: string) {
    if (!type) {
      setDose(val);
      if (typeof ProgesteroneDetails.dose[val] === 'string') {
        setOpenWarningPopup(true);
      }
      if (ProgesteroneDetails.dose[val] >= 0.4) {
        setDTD(60);
      } else {
        setDTD(30);
      }
    } else {
      if (typeof ProgesteroneDetails.dose[dose + val] === 'string') {
        setOpenWarningPopup(true);
      }
      if (ProgesteroneDetails.dose[dose + val] >= 0.4) {
        setDTD(60);
      } else {
        setDTD(30);
      }
    }
  }

  const handleConfirmation = () => {
    navigate({
      search: 'rx=oral-micronized-progesterone',
    });
    setOpenWarningPopup(false);
  };

  return {
    ProgesteroneDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    deliveryMethod,
    setDeliveryMethod,

    handleDose,
    openWarningPopup,
    setOpenWarningPopup,

    handleConfirmation,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  };
};
