import { TitrationRxType } from 'types/patient/prescription';
import { HormoneNames, PrescribedObjectType } from 'types/prescriptionTypes';

export function transformMedication(item: PrescribedObjectType) {
  const ratio = item.ratio?.split(':');

  return {
    medicationId: item.id || '',
    refills: item.refills,
    deliveryMethodAndSig: {
      deliveryMethod: item.deliveryMethod || '',
      sig: item.sig,
      ...(!!item?.dose && {
        dose: {
          unit: item?.doseUnit?.toLowerCase(),
          value: item?.dose,
        },
      }),
      ...(!!item?.minimumDose && {
        minDailyDose: {
          unit: item?.doseUnit?.toLowerCase(),
          value: item?.minimumDose,
        },
      }),
    },
    strength: {
      unit: item?.strengthUnit,
      value: item?.strength,
    },

    ...(item.ratio && {
      ratio: {
        e3: ratio?.[0],
        e2: ratio?.[1],
      },
    }),
    ...(!!item.dtd && {
      dtd: {
        unit: item.dtdUnit?.toLowerCase() || 'ml',
        value: Number(item.dtd),
      },
    }),
    ...(!!item?.note && {
      notes: item?.note,
    }),
  };
}

export function transformPrescription(item: TitrationRxType) {
  const ratio = `${item.prescription?.ratio?.e3}:${item.prescription?.ratio?.e2}`;

  return {
    id: item.medicationId,
    displayName: item?.name as HormoneNames,
    name: item?.name as HormoneNames,
    strength: item.prescription?.strength?.value,
    strengthUnit: item.prescription?.strength?.unit,
    ...(item.prescription?.ratio?.e3 && { ratio }),
    ratioUnit: item.prescription?.ratio?.e3 ? 'E3:E2' : undefined,
    dose: item.prescription?.deliveryMethodAndSig?.dose?.value,
    doseUnit: item.prescription?.deliveryMethodAndSig?.dose?.unit,
    refills: 0,
    dtd: item.prescription?.dtd?.value,
    dtdUnit: item.prescription?.dtd?.unit,
    sig: item.prescription?.deliveryMethodAndSig?.sig,
    deliveryMethod: item.prescription?.deliveryMethodAndSig?.deliveryMethod,
    family: item.prescription?.family,
    isFromTitration: true,
    coveredUnderSubscription: item.medication?.isCoveredUnderSubscription,
  };
}
