import { HormoneCompoundBaseName, HormoneNames } from 'types/prescriptionTypes';

import { DeliveryMethods } from './delivery-methods';

// eslint-disable-next-line
export const femaleHormoneRxTypes: any = {
  'bi-est': {
    displayName: HormoneNames.BI_EST,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    link: 'bi-est',
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    linkedHormone: HormoneNames.ESTRADIOL,
    strength: [30, 60],
    strengthUnit: 'mg/ml',
    ratio: ['80:20', '70:30', '60:40', '50:50', '40:60', '30:70', '20:80'],
    ratioUnit: 'E3:E2',
    dtd: 30,
    dose: [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5],
    doseUnit: 'ml/day',
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Estradiol',
    statusUnit: 'mgeeq/day',
  },
  // estradiol: {
  //   displayName: 'Estradiol',
  //   name: HormoneNames.ESTRADIOL,
  //   link: 'estradiol',
  //   deliveryMethod: DeliveryMethods.Syringe.name,
  //   linkedHormone: HormoneNames.BI_EST,
  //   strength: [
  //     10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  //     28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
  //     46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  //   ],
  //   strengthUnit: 'mg/ml',
  //   dtd: 30,
  //   dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  //   doseUnit: 'ml/day',
  //   refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  //   family: 'Estradiol',
  // },
  progesterone: {
    displayName: HormoneNames.PROGESTERONE,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    link: 'progesterone',
    linkedHormone: HormoneNames.ORAL_PROGESTERONE,
    strength: '200',
    strengthUnit: 'mg/ml',
    dtd: [30, 60],
    eyeDropperDose: [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, '13<'],
    eyeDropperDoseUnit: 'drops/day',
    statusUnit: 'mg/day',
    oralSyringDose: [
      0.05,
      0.1,
      0.15,
      0.2,
      0.25,
      0.3,
      0.35,
      0.4,
      0.45,
      0.5,
      0.55,
      0.6,
      0.65,
      0.7,
      0.75,
      0.8,
      0.85,
      0.9,
      0.95,
      1.0,
      1.05,
      1.1,
      1.15,
      1.2,
      1.25,
      1.3,
      1.35,
      1.4,
      1.45,
      1.5,
      1.55,
      1.6,
      '2<',
    ],
    dose: [
      0.05,
      0.1,
      0.15,
      0.2,
      0.25,
      0.3,
      0.35,
      0.4,
      0.45,
      0.5,
      0.55,
      0.6,
      0.65,
      0.7,
      0.75,
      0.8,
      0.85,
      0.9,
      1,
      1.05,
      1.1,
      1.15,
      1.2,
      1.25,
      1.3,
      1.35,
      1.4,
      1.45,
      1.5,
      1.55,
      1.6,
      '2<',
    ],
    doseUnit: 'ml/day',
    oralSyringDoseUnit: 'ml',

    refills: [0, 1, 2, 3, 4, 5],

    family: 'Progesterone',
  },
  'oral-micronized-progesterone': {
    displayName: HormoneNames.ORAL_PROGESTERONE,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'oral-micronized-progesterone',
    deliveryMethod: DeliveryMethods.Capsules.name,
    linkedHormone: HormoneNames.PROGESTERONE,
    strength: [25, 50, 75, 115, 125, 150, 175, 225, 250, 275, 300, 325],
    strengthUnit: 'mg',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Progesterone',
  },
  'testosterone/dhea': {
    displayName: HormoneNames.TESTO_PER_DHEA,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    link: 'testosterone/dhea',
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    strength: ['8/160', '24/160'],
    strengthUnit: 'mg/ml',
    statusUnit: 'T/DHEA mg/day',
    dose: [
      0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8,
      0.85, 0.9, 0.95, 1,
    ],
    doseUnit: 'ml/day',
    refills: [0, 1, 2, 3, 4, 5],
    dtd: 30,
    family: 'Testosterone/DHEA',
  },
  testosterone: {
    displayName: HormoneNames.TESTOSTERONE_WOMEN,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    link: 'testosterone',
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    strength: [8, 24],
    strengthUnit: 'mg/ml',
    statusUnit: 'mg/day',
    refills: [0, 1, 2, 3, 4, 5],
    dose: [
      0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
      0.75, 0.8, 0.85, 0.9, 0.95, 1,
    ],
    doseUnit: 'ml/day',
    dtd: 30,
    family: 'Testosterone/DHEA',
  },
  dhea: {
    displayName: HormoneNames.DHEA,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    link: 'dhea',
    statusUnit: 'mg/day',
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    strength: 160,
    strengthUnit: 'mg/ml',
    refills: [0, 1, 2, 3, 4, 5],
    dose: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    doseUnit: 'ml/day',
    dtd: 30,
    family: 'Testosterone/DHEA',
  },
  'dhea-oral': {
    displayName: HormoneNames.DHEA_ORAL,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'dhea-oral',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    deliveryMethod: DeliveryMethods.Capsules.name,
    strength: [5, 7.5, 10, 12.5, 15, 25, 50],
    strengthUnit: 'mg',
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Testosterone/DHEA',
  },
  'levothyroxine-(t4)': {
    displayName: HormoneNames.LEVOTHYROXINE,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'levothyroxine-(t4)',
    strength: '50',
    strengthUnit: 'mcg',
    deliveryMethod: DeliveryMethods.Tablets.name,
    dose: [0.5, 1.0, 1.5, 2.0, 2.5, 3],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'T4',
  },
  'liothyronine-extended-release-(lat3)': {
    displayName: HormoneNames.LIOTHYRONINE_EXT,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'liothyronine-extended-release-(lat3)',
    deliveryMethod: DeliveryMethods.Capsules.name,
    strength: [5, 10, 15, 20, 25],
    strengthUnit: 'mcg',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'LAT3',
  },
  't4/lat3': {
    displayName: HormoneNames.T4_PER_LAT3,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 't4/lat3',
    strength: [
      '50/5',
      '50/10',
      '75/10',
      '75/15',
      '100/15',
      '100/20',
      '125/15',
      '125/20',
      '125/25',
      '150/15',
      '150/20',
      '150/25',
    ],
    strengthUnit: 'mcg',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    deliveryMethod: DeliveryMethods.Capsules.name,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'T4+LAT3',
  },
  sildenafil: {
    displayName: HormoneNames.SILDENAFIL,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'sildenafil',
    deliveryMethod: DeliveryMethods.Tablets.name,
    strengthUnit: 'mg',
    strength: [20, 50, 100],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Sildenafil/Tadalafil',
  },
  tadalafil: {
    displayName: HormoneNames.TADALAFIL,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'tadalafil',
    deliveryMethod: DeliveryMethods.Tablets.name,
    strengthUnit: 'mg',
    strength: [5, 10, 20],
    dtd: [60],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Sildenafil/Tadalafil',
  },
  danazol: {
    displayName: HormoneNames.Danazol,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'danazol',
    deliveryMethod: DeliveryMethods.Capsules.name,
    strengthUnit: 'mg',
    strength: [6.25, 12.5, 18.75, 25],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
  },
};
// eslint-disable-next-line
export const maleHormoneRxTypes: any = {
  'levothyroxine-(t4)': {
    displayName: HormoneNames.LEVOTHYROXINE,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'levothyroxine-(t4)',
    strength: '50',
    strengthUnit: 'mcg',
    deliveryMethod: DeliveryMethods.Tablets.name,
    dose: [0.5, 1.0, 1.5, 2.0, 2.5, 3],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'T4',
  },
  'liothyronine-extended-release-(lat3)': {
    displayName: HormoneNames.LIOTHYRONINE_EXT,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'liothyronine-extended-release-(lat3)',
    deliveryMethod: DeliveryMethods.Capsules.name,
    strength: [5, 10, 15, 20, 25],
    strengthUnit: 'mcg',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'LAT3',
  },
  't4/lat3': {
    displayName: HormoneNames.T4_PER_LAT3,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 't4/lat3',
    strength: [
      '50/5',
      '50/10',
      '75/10',
      '75/15',
      '100/15',
      '100/20',
      '125/15',
      '125/20',
      '125/25',
      '150/15',
      '150/20',
      '150/25',
    ],
    strengthUnit: 'mcg',
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    deliveryMethod: DeliveryMethods.Capsules.name,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'T4+LAT3',
  },
  sildenafil: {
    displayName: HormoneNames.SILDENAFIL,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'sildenafil',
    deliveryMethod: DeliveryMethods.Tablets.name,
    strengthUnit: 'mg',
    strength: [20, 50, 100],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Sildenafil/Tadalafil',
  },
  tadalafil: {
    displayName: HormoneNames.TADALAFIL,
    compoundName: HormoneCompoundBaseName.Brite,
    link: 'tadalafil',
    deliveryMethod: DeliveryMethods.Tablets.name,
    strengthUnit: 'mg',
    strength: [5, 10, 20],
    dtd: [60],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Tablets.name}/day`,
    dtdUnit: DeliveryMethods.Tablets.name,
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Sildenafil/Tadalafil',
  },
  danazol: {
    displayName: HormoneNames.Danazol,
    compoundName: HormoneCompoundBaseName.BriteCompound,
    link: 'danazol',
    deliveryMethod: DeliveryMethods.Capsules.name,
    strengthUnit: 'mg',
    strength: [6.25, 12.5, 18.75, 25],
    dose: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    doseUnit: `${DeliveryMethods.Capsules.name}/day`,
    dtd: [60],
    dtdUnit: DeliveryMethods.Capsules.name,
    refills: [0, 1, 2, 3, 4, 5],
  },
  testosterone: {
    displayName: HormoneNames.TESTOSTERONE_MEN,
    compoundName: HormoneCompoundBaseName.BriteTopical,
    link: 'testosterone',
    strength: '186',
    strengthUnit: 'mg/ml',
    deliveryMethod: DeliveryMethods.OralSyringe.name,
    dtd: [30, 60],
    statusUnit: 'mg/day',
    dose: [
      0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75,
      0.8, 0.85, 0.9, 0.95, 1, 1.05, 1.1, 1.15,
    ],
    doseUnit: 'ml/day',
    refills: [0, 1, 2, 3, 4, 5],

    family: 'Testosterone',
  },
  'testosterone-cyp-inj-200mg/ml': {
    displayName: HormoneNames.TestosteroneCyp,
    compoundName: HormoneCompoundBaseName.Brite,
    strength: '200',
    strengthUnit: 'mg/ml',
    dtd: [30, 60],
    deliveryMethod: DeliveryMethods.Syringe.name,
    link: 'testosterone-cyp-inj-200mg/ml',
    refills: [0, 1, 2, 3, 4, 5],
    family: 'Testosterone',
  },
  'pregnyl-(injectable)(hcg)': {
    displayName: HormoneNames.Pregnyl,
    compoundName: HormoneCompoundBaseName.Brite,
    strength: '10000',
    strengthUnit: 'iu kit',
    deliveryMethod: DeliveryMethods.Vial.name,
    link: 'pregnyl-(injectable)(hcg)',
    refills: [0, 1, 2, 3, 4, 5],
  },
};
