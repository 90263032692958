import classNames from 'classnames';

interface CheckBoxProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  setRef?: (ref: HTMLInputElement | null) => void;
  disable?: boolean;
}

export function CheckBox({
  id,
  onChange,
  className,
  label,
  checked,
  setRef,
  disable,
}: CheckBoxProps) {
  return (
    <div className={classNames(' flex items-center text-md   ', className)}>
      <input
        ref={(ref) => setRef?.(ref)}
        className={classNames(
          'h-6 w-4  cursor-pointer border accent-primary-main  ',
          {
            '!cursor-not-allowed': disable,
          }
        )}
        id={id}
        onChange={
          disable
            ? undefined
            : (e) => {
                onChange(e.target.checked);
              }
        }
        type="checkbox"
        checked={checked}
      />
      <span className="mr-2" />
      <label className="text-md text-background-contrastText" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
