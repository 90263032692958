import { useState } from 'react';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';

export const useTestosteroneWomenController = () => {
  const TestosteroneWomenDetails = femaleHormoneRxTypes?.testosterone;
  const [strength, setStrength] = useState(8);
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(18);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');

  return {
    TestosteroneWomenDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  };
};
