import React, { CSSProperties } from 'react';

import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';

type TooltipProps = {
  children?: React.ReactNode;
  id: string;
  theme?: string;
} & ITooltip;

const style: { [key: string]: CSSProperties } = {
  light: { background: 'white', color: 'black', fontFamily: 'Verdana' },
  dark: { background: 'black', color: 'white', fontFamily: 'Verdana' },
};

export function Tooltip({
  children,
  id,
  theme = 'light',
  ...rest
}: TooltipProps) {
  return (
    <ReactTooltip
      clickable
      className="flex max-w-[50vw] flex-wrap"
      id={id}
      style={style[theme]}
      {...rest}>
      {children}
    </ReactTooltip>
  );
}
