import { formatInTimeZone } from 'date-fns-tz';

import { timeZones } from './timezones';

export class GMTDate extends Date {
  static DEFAULT_TIMEZONE = 'Etc/GMT';
  static LOCAL_TIMEZONE = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  static DEFAULT_TIMEZONE_ABBREVIATION = GMTDate.getTimezoneAbbreviation(
    GMTDate.DEFAULT_TIMEZONE
  );
  static LOCAL_TIMEZONE_ABBREVIATION = GMTDate.getTimezoneAbbreviation(
    GMTDate.LOCAL_TIMEZONE
  );
  constructor(
    date: string = new Date(
      Date.now() + new Date().getTimezoneOffset() * 60 * 1000
    ).toISOString()
  ) {
    let _date = date;
    switch (true) {
      case /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+$/.test(date): {
        _date = `${date}Z`;
        break;
      }
      case /^\d{8}$/.test(date): {
        _date = `${date.replace(
          /^(\d{4})(\d{2})(\d{2})$/,
          '$1-$2-$3'
        )}T00:00:00.000Z`;
        break;
      }

      case /^\d{4}-\d{2}-\d{2}$/.test(date): {
        _date = `${date}T00:00:00.000`;
        break;
      }
      default:
        _date = date;
    }

    super(_date);
  }

  static getTimezoneAbbreviation(timezone: string) {
    return timeZones.find((tz) => tz.utc.includes(timezone))?.abbr;
  }

  getYYYYMMDDformat() {
    return formatInTimeZone(this, GMTDate.LOCAL_TIMEZONE, 'yyyy-MM-dd');
  }

  getReadableDate(withTimezone = false) {
    const formattedTime = formatInTimeZone(
      this,
      GMTDate.LOCAL_TIMEZONE,
      'MMM d, yyyy'
    );

    return withTimezone
      ? `${formattedTime} (${GMTDate.LOCAL_TIMEZONE_ABBREVIATION})`
      : formattedTime;
  }

  getReadableDateWithTime(withTimezone = false) {
    const formattedTime = formatInTimeZone(
      this,
      GMTDate.LOCAL_TIMEZONE,
      'MMM d, yyyy, h:mm aaa'
    ).replace(/(a|p)m/g, (match) => match.toUpperCase());

    return withTimezone
      ? `${formattedTime} (${GMTDate.LOCAL_TIMEZONE_ABBREVIATION})`
      : formattedTime;
  }

  getYYYYMMDDformatInGMT() {
    return formatInTimeZone(this, GMTDate.DEFAULT_TIMEZONE, 'yyyy-MM-dd');
  }

  getReadableDateInGMT(withTimezone = false) {
    const formattedTime = formatInTimeZone(
      this,
      GMTDate.DEFAULT_TIMEZONE,
      'MMM d, yyyy'
    );

    return withTimezone
      ? `${formattedTime} (${GMTDate.DEFAULT_TIMEZONE_ABBREVIATION})`
      : formattedTime;
  }

  getReadableDateWithTimeInGMT(withTimezone = false) {
    const formattedValue = formatInTimeZone(
      this,
      GMTDate.DEFAULT_TIMEZONE,
      'MMM d, yyyy, h:mm aaa'
    ).replace(/(a|p)m/g, (match) => match.toUpperCase());

    return withTimezone
      ? `${formattedValue} (${GMTDate.DEFAULT_TIMEZONE_ABBREVIATION})`
      : formattedValue;
  }
}
