import snakecaseKeys from 'snakecase-keys';

import { APIService } from 'services/api-service';
import {
  DosageHistory,
  GetDoseHistoryBody,
  PrescriptionCheckBody,
  PrescriptionCheckType,
  TitrationConfigType,
  TitrationHormoneType,
  TitrationRxType,
  UpdateDoseBody,
} from 'types/patient/prescription';

export class PatientPrescriptionService extends APIService {
  static CHECK_PRESCRIPTION = `${this.API_PREFIX_URL}/medication/check-prescription`;
  static checkPrescription = async ({
    patientId,
    medication,
  }: PrescriptionCheckBody) =>
    this.post<PrescriptionCheckType>(
      `${PatientPrescriptionService.CHECK_PRESCRIPTION}?patient_id=${patientId}`,
      {
        ...medication,
        delivery_method_and_sig: JSON.stringify(
          snakecaseKeys(medication.deliveryMethodAndSig || {})
        ),
        dtd: JSON.stringify(medication.dtd),
        ratio: JSON.stringify(medication.ratio),
        strength: JSON.stringify(medication.strength),
      },
      {
        withAuthToken: true,
      }
    );

  static PRESCRIPTION_CHECK_URL = `${this.API_PREFIX_URL}/medication/check-prescription`;

  static getPrescriptionCheckValue = async (prescriptionId: string) => {
    const url = new URL(`${this.CHECK_PRESCRIPTION}/${prescriptionId}`);

    return this.get<PrescriptionCheckType>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_TITRATION_CONFIG_URL = `${this.API_PREFIX_URL}/medication/titrations`;
  static getTitrationConfig = async (
    titrationType: TitrationConfigType | undefined
  ) =>
    this.get<TitrationHormoneType[]>(
      `${PatientPrescriptionService.GET_TITRATION_CONFIG_URL}?titration_type=${titrationType}`,
      {
        withAuthToken: true,
      }
    );

  static GET_TITRATION_RX_CONFIG_URL = `${this.API_PREFIX_URL}/medication/titrations/prescriptions`;
  static getTitrationRxConfig = async (titrationType: TitrationConfigType) =>
    this.get<TitrationRxType[]>(
      `${PatientPrescriptionService.GET_TITRATION_RX_CONFIG_URL}?titration_type=${titrationType}`,
      {
        withAuthToken: true,
      }
    );

  static DELETE_PARKED_PRESCRIPTIONS = `${this.API_PREFIX_URL}/medication/parked-prescriptions/`;
  static deleteAllParkedPrescriptions = async (patientId: string) =>
    this.delete(
      `${PatientPrescriptionService.DELETE_PARKED_PRESCRIPTIONS}${patientId}`,
      {
        withAuthToken: true,
      }
    );

  static UPDATE_DOSE_URL = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static updateDose = async ({
    dose,
    prescriptionId,
    patientId,
  }: UpdateDoseBody) => {
    const url = new URL(
      `${PatientPrescriptionService.UPDATE_DOSE_URL}/${prescriptionId}/dose`
    );
    url.searchParams.append('patient_id', String(patientId));

    return this.put<PrescriptionCheckType>(
      url.toString(),
      {
        current_dose: dose,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static GET_DOSAGE_HISTORY_URL = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static getDoseHistory = async ({
    prescriptionId,
    patientId,
  }: GetDoseHistoryBody) => {
    const url = new URL(
      `${PatientPrescriptionService.GET_DOSAGE_HISTORY_URL}/${prescriptionId}/dose-history`
    );
    url.searchParams.append('patient_id', String(patientId));

    return this.get<DosageHistory[]>(url.toString(), {
      withAuthToken: true,
    });
  };
}
