import React from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PatientPrescriptionService } from 'services/prescription';
import { PatientDetailsContextType } from 'types/patient';
import {
  TitrationConfigType,
  TitrationHormoneType,
} from 'types/patient/prescription';

import { TitrationDetailsSkeleton } from './TitrationDetailsSkeleton';

interface TitrationDetailsProps {
  titrationType?: TitrationConfigType;
  titrationData?: TitrationHormoneType[];
  loading?: boolean;
}

const titrationProperties = [
  //   { label: 'Weeks', count: 6 },
  { label: 'Days', count: 35 },
];

export function TitrationDetails({
  titrationType,
  titrationData,
  loading,
}: TitrationDetailsProps) {
  const navigate = useNavigate();
  const { gender } = useOutletContext<PatientDetailsContextType>();

  const getTitrationConfigQuery = useQuery(
    [
      QueryKeys.TitrationConfig.listing({
        gender,
        titrationType,
      }),
    ],
    () =>
      PatientPrescriptionService.getTitrationConfig(titrationType ?? undefined),
    {
      enabled: !!titrationType,
    }
  );

  const data = titrationType
    ? getTitrationConfigQuery.data?.data
    : titrationData;

  const dataLoading = titrationType
    ? getTitrationConfigQuery.isLoading
    : loading;

  const getFontColor = (index: number) => {
    switch (true) {
      case index < 7:
        return 'text-lime-300';
      case index < 14:
        return 'text-orange-300';
      case index < 21:
        return 'text-fuchsia-300';
      case index < 28:
        return 'text-violet-400';
      case index < 35:
        return 'text-red-400';
      default:
        return 'text-background-contrastText';
    }
  };

  return (
    <div className="flex flex-col bg-background-main  p-8   ">
      <div
        className={classNames('block', {
          '!hidden': !titrationType,
        })}>
        <Typography variant="h3" color="textPrimary">
          Titration -
          <Typography
            variant="h3"
            color="white"
            className="mx-2 rounded-sm bg-primary-main px-2">
            {titrationType}(s)
          </Typography>
        </Typography>
      </div>
      {dataLoading ? (
        <TitrationDetailsSkeleton />
      ) : (
        <>
          <div className="mt-4 grid grid-cols-12 ">
            <div className="col-span-2 ">
              <ul className="border border-zinc-600">
                {titrationProperties.map(({ label }) => (
                  <li
                    className={classNames(
                      'flex  items-center justify-center  border-b border-zinc-600  '
                    )}>
                    <Typography className="h-10" variant="h5">
                      {label}
                    </Typography>
                  </li>
                ))}
                {data?.map(({ name }, index, arr) => (
                  <>
                    <li
                      className={classNames(
                        'flex h-12 flex-col items-start  justify-center border-b border-zinc-600 pl-4  '
                      )}>
                      <Typography variant="h5"> {name}</Typography>
                      <Typography color="primary" variant="subtitle3">
                        Morning dose/ml
                      </Typography>
                    </li>
                    <li
                      className={classNames(
                        'flex h-12 flex-col items-start  justify-center border-zinc-600 pl-4   ',
                        {
                          'border-b': index !== arr.length - 1,
                        }
                      )}>
                      <Typography variant="h5"> {name}</Typography>
                      <Typography color="primary" variant="subtitle3">
                        Evening dose/ml
                      </Typography>
                    </li>
                  </>
                ))}
              </ul>
            </div>
            <div className="col-span-10 flex-col  overflow-auto border-t border-r  border-zinc-600 bg-background-light">
              <div className="w-full ">
                {titrationProperties.map(({ count }) => (
                  <div className="flex">
                    {[...Array(count)].map((i, index) => (
                      <div className="   flex h-10 items-center justify-center     border-r border-zinc-600   ">
                        <div className="flex  w-10 items-center justify-center   ">
                          <Typography variant="h5" className={classNames('  ')}>
                            {index + 1}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {data?.map((item) => (
                <div className="flex">
                  {item.titration.map((i, index) => (
                    <ul className="    border-t border-r border-zinc-600  ">
                      <li className="flex h-12  w-10 items-center justify-center ">
                        <Typography
                          className={classNames(`${getFontColor(index)}`)}
                          variant="h5">
                          {i.morning ?? '-'}
                        </Typography>
                      </li>

                      <li className="flex h-12  w-10 items-center justify-center border-t  border-zinc-600">
                        <Typography
                          className={classNames(`${getFontColor(index)}`)}
                          variant="h5">
                          {i.evening ?? '-'}
                        </Typography>
                      </li>
                    </ul>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div>
            <Typography className="mt-2 mr-2" color="error">
              NOTE : The dosage might change if the patient reports any symptoms
              during the titration process.
            </Typography>
          </div>
          <div
            className={classNames('flex justify-end', {
              '!hidden': !titrationType,
            })}>
            <Button
              onClick={() => navigate(-1)}
              color="secondary"
              className=" mr-8 !px-16">
              Back
            </Button>
            <Button
              onClick={() => {
                navigate({
                  search: `titrationType=${titrationType}`,
                  pathname: '../e-prescribe/',
                });
              }}
              color="primary"
              className="!px-16">
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
