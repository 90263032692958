import {
  PrescribedObjectType,
  PrescribedObjectTypes,
  ReducerAction,
  ReducerActionNew,
  ReducerActionType,
  ReducerActionTypeNew,
} from 'types/prescriptionTypes';

export const hormoneReducer = (
  state: PrescribedObjectTypes[] | PrescribedObjectType[],
  action: ReducerAction
) => {
  switch (action.type) {
    case ReducerActionType.ADD_HORMONE:
      return [...state, action.payload.hormoneObject];

    case ReducerActionType.DELETE_HORMONE: {
      const tempHormoneArray = [...state];
      tempHormoneArray.splice(action.payload.index, 1);

      return [...tempHormoneArray];
    }

    case ReducerActionType.UPDATE_HORMONE: {
      const tempHormoneArray = [...state];
      tempHormoneArray[action.payload?.index] = action.payload.hormoneObject;

      return [...tempHormoneArray];
    }

    default:
      return state;
  }
};
export const hormoneReducerNew = (
  state: PrescribedObjectType[],
  action: ReducerActionNew
) => {
  switch (action.type) {
    case ReducerActionTypeNew.ADD_HORMONE:
      return [...state, action.payload.hormoneObject];

    case ReducerActionTypeNew.DELETE_HORMONE: {
      const tempHormoneArray = [...state];
      tempHormoneArray.splice(action.payload.index, 1);

      return [...tempHormoneArray];
    }

    case ReducerActionTypeNew.UPDATE_HORMONE: {
      const tempHormoneArray = [...state];
      tempHormoneArray[action.payload?.index] = action.payload.hormoneObject;

      return [...tempHormoneArray];
    }

    default:
      return state;
  }
};
