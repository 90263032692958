import React, { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { PatientService } from 'services/patient';
import { NCStatusDetails, OrderItem } from 'services/patient/types';

type PrescriptionNCStatusModalType = {
  orderItems: OrderItem[];
  disabled: boolean;
  open: boolean;
  onTransmit: (failedPrescriptions: string[]) => void;
  onCancel: () => void;
};

export function PrescriptionNCStatusModal({
  orderItems,
  disabled,
  onCancel,
  onTransmit,
  open,
}: PrescriptionNCStatusModalType) {
  const [NCStatuses, setNCStatuses] = useState<{
    [x: string]: NCStatusDetails;
  }>();
  const orderPrescriptionNCStatusMutation = useMutation(
    (prescriptionId: string) =>
      PatientService.getOrderPrescriptionNCStatus(prescriptionId),
    {
      retry: false,
      onSuccess(data, variables) {
        setNCStatuses((pre) => ({
          ...pre,
          [variables]: data?.data,
        }));
      },
    }
  );

  function getNCStatuses() {
    orderItems.forEach((i) => {
      orderPrescriptionNCStatusMutation.mutate(i.prescription.id);
    });
  }

  function getTooltipForTransmitButton() {
    if (orderPrescriptionNCStatusMutation.isLoading) {
      return 'Please wait until the prescription transmission status are fetched.';
    }
    if (
      Object.values(NCStatuses ?? {}).every((s) => s.ncStatus === 'Verified') &&
      NCStatuses
    ) {
      return 'You don’t need to retransmit the prescription(s) since they are already transmitted.';
    }

    return undefined;
  }

  useEffect(() => {
    if (orderItems?.[0]?.id && !disabled) {
      getNCStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems]);

  return (
    <Modal
      className="xl:w-1/2 "
      open={open}
      closeModal={() => {
        //
      }}>
      <div className="flex flex-col px-2">
        <Typography variant="h4" className="mb-4">
          Preparing to retransmit the prescription(s).
        </Typography>
        <div className="">
          <Table
            rowsData={orderItems}
            columns={[
              {
                title: 'Rx(s)',
                render: (i) => i.prescription?.standardRepresentation ?? 'NA',
              },
              {
                title: 'Transmission Status',
                render: (i) => (
                  <span className="flex min-h-[1.5rem] justify-center">
                    {!orderPrescriptionNCStatusMutation.isLoading ? (
                      NCStatuses?.[i?.prescription?.id]?.ncStatus ??
                      'Not Available'
                    ) : (
                      <div className=" h-6 w-32 animate-pulse rounded bg-background-dark" />
                    )}
                  </span>
                ),
                classNames: 'text-center',
              },
            ]}
          />
        </div>
        <div className="mt-4 flex justify-end gap-8 ">
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              const failedPrescription = Object.values(NCStatuses ?? {}).filter(
                (s) => s.ncStatus !== 'Verified'
              );
              onTransmit([...failedPrescription.map((fp) => fp.id)]);
            }}
            disabled={!!getTooltipForTransmitButton()}
            data-tooltip-id="transmitButton">
            Transmit
          </Button>
          {!!getTooltipForTransmitButton() && (
            <Tooltip theme="dark" id="transmitButton">
              {getTooltipForTransmitButton()}
            </Tooltip>
          )}
        </div>
      </div>
    </Modal>
  );
}
