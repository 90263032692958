import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { ContainerSkeleton } from 'components/common/skeletons/ContainerSkeleton';
import { Typography } from 'components/common/Typography/Typography';
import { PrescribedHormonesNew } from 'components/prescriptions/PrescribedHormones/PrescribedHormonesNew';
import { PrescriptionDetailsNew } from 'components/prescriptions/PrescriptionDetails/PrescriptionDetailsNew';
import {
  femaleHormoneRxTypes,
  maleHormoneRxTypes,
} from 'constants/hormone-details';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { hormoneReducerNew } from 'reducers/prescription-reducer';
import { PatientService } from 'services/patient';
import { PatientPrescriptionService } from 'services/prescription';
import { Gender, PatientDetailsContextType } from 'types/patient';
import {
  PatientMedication,
  TitrationConfigType,
} from 'types/patient/prescription';
import {
  PrescribedObjectType,
  ReducerActionTypeNew,
} from 'types/prescriptionTypes';
import './RxTypes.css';
import { normalizeString } from 'utils/common';

import { transformPrescription } from '../common';

type OpenWarningPopupType = {
  state: boolean;
  details?: {
    name?: string;
    family: string;
    newHormoneName: string;
  };
};

export function EPrescribeNew() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const queryClient = useQueryClient();
  const [titrationType] = useState(searchParams.get('titrationType'));
  const [excludedHormones, setExcludedHormones] = useState<string[]>([]);

  const [showPrescriptionConfirmation, setShowPrescriptionConfirmation] =
    useState(false);

  const {
    id: patientId,
    gender,
    firstName,
    lastName,
  } = useOutletContext<PatientDetailsContextType>();

  const getTitrationRxConfigQuery = useQuery(
    [
      QueryKeys.TitrationRxConfig.listing({
        titrationType: titrationType as string,
      }),
    ],
    () =>
      PatientPrescriptionService.getTitrationRxConfig(
        titrationType as TitrationConfigType
      ),
    { refetchOnMount: false, enabled: !!patientId && !!titrationType }
  );

  const getHormonesQuery = useQuery(
    [QueryKeys.Hormones],
    () => PatientService.getPatientMedication(gender || ''),
    { enabled: !!gender }
  );
  const prescribeMedicationMutation = useMutationWithToast(
    PatientService.prescribeMedication,
    {
      onSuccess() {
        sessionStorage.removeItem(`hormones${patientId}`);
      },
    }
  );
  const [openWarningPopup, setOpenWarningPopup] =
    useState<OpenWarningPopupType>({
      state: false,
    });
  const initialState: PrescribedObjectType[] = JSON.parse(
    (sessionStorage.getItem(`hormones${patientId}`) as string) ?? '[]'
  );
  const [isPrescribeActive, setIsPrescribeActive] = useState(
    !initialState.length
  );

  const [prescribedHormones, dispatch] = useReducer(
    hormoneReducerNew,
    initialState
  );
  useEffect(() => {
    if (titrationType) {
      setIsPrescribeActive(false);
    }
  }, [titrationType]);

  const getHormoneId = useCallback(() => {
    let id;
    getHormonesQuery?.data?.data.some((item) => {
      if (normalizeString(item.name) === rx) {
        id = item.id;
      }

      return normalizeString(item.name) === rx;
    });

    return id;
  }, [getHormonesQuery?.data, rx]);

  const checkAlreadyPrescribedItem = useCallback(
    ({ name, family }: PrescribedObjectType) => {
      let index = -1;
      let isRepeated = false;
      const existingHormoneDetails = {
        name: '',
        family: family ?? '',
        newHormoneName: name ?? '',
      };

      // eslint-disable-next-line no-plusplus
      for (let i = prescribedHormones.length - 1; i >= 0; i--) {
        if (prescribedHormones[i].name === name) {
          index = i;
          break;
        }
      }
      if (family && index === -1) {
        if (
          prescribedHormones?.some((item) => {
            if (item?.family === family) {
              existingHormoneDetails.name = item.name || '';
            }

            return item?.family === family;
          })
        ) {
          isRepeated = true;
        }
      }

      return { index, isRepeated, existingHormoneDetails };
    },
    [prescribedHormones]
  );

  const addNewPrescription = useCallback(
    async (prescribedItem: PrescribedObjectType) => {
      const { index, isRepeated, existingHormoneDetails } =
        checkAlreadyPrescribedItem(prescribedItem);

      if (isRepeated && existingHormoneDetails?.name) {
        setOpenWarningPopup({
          state: true,
          details: existingHormoneDetails,
        });

        return;
      }
      setIsPrescribeActive(false);
      const id = getHormoneId() || prescribedItem?.id;
      let coveredUnderSubscription = searchParams.get('covered') ?? 'false';
      if (prescribedItem.coveredUnderSubscription) {
        coveredUnderSubscription = prescribedItem?.coveredUnderSubscription;
      }

      if (!id) {
        return;
      }
      if (index > -1) {
        dispatch({
          type: ReducerActionTypeNew.UPDATE_HORMONE,
          payload: {
            hormoneObject: {
              ...prescribedItem,
              id,
              coveredUnderSubscription,
            },
            index,
          },
        });
        if (!prescribedItem.isFromTitration) {
          sessionStorage.setItem(
            `hormones${patientId}`,
            JSON.stringify([
              ...prescribedHormones.map((hormone) => {
                if (id === hormone.id) {
                  return {
                    ...prescribedItem,
                    id,
                    coveredUnderSubscription,
                  };
                }

                return hormone;
              }),
            ])
          );
        }
      } else {
        dispatch({
          type: ReducerActionTypeNew.ADD_HORMONE,
          payload: {
            hormoneObject: {
              ...prescribedItem,
              id,
              coveredUnderSubscription,
            },
            index: -1,
          },
        });
        if (!prescribedItem.isFromTitration) {
          sessionStorage.setItem(
            `hormones${patientId}`,
            JSON.stringify([
              ...prescribedHormones,
              { ...prescribedItem, id, coveredUnderSubscription },
            ])
          );
        }
      }
    },
    [
      checkAlreadyPrescribedItem,
      getHormoneId,
      patientId,
      prescribedHormones,
      searchParams,
    ]
  );
  useEffect(() => {
    if (titrationType) {
      getTitrationRxConfigQuery.data?.data?.forEach((item) => {
        addNewPrescription(transformPrescription(item));
      });
    }
    // eslint-disable-next-line
  }, [titrationType, getTitrationRxConfigQuery.data?.data]);
  const removePrescribedItem = (index: number, id: string) => {
    dispatch({
      type: ReducerActionTypeNew.DELETE_HORMONE,
      payload: { hormoneObject: { refills: 0 }, index },
    });
    prescribedHormones.some((item) => {
      let successful = false;
      if (item.id === id) {
        if (item.isFromTitration) {
          successful = true;
          setExcludedHormones([...excludedHormones, id]);
        }
      }

      return successful;
    });

    sessionStorage.setItem(
      `hormones${patientId}`,
      JSON.stringify([
        ...prescribedHormones.filter((hormone) => id !== hormone.id),
      ])
    );
  };

  const rxTypeClickHandler = (item: PatientMedication) => {
    const name = normalizeString(item.name);
    if (gender === Gender.Male) {
      navigate({
        search: `rx=${maleHormoneRxTypes[name]?.link}&id=${item.id}&covered=${item.isCoveredUnderSubscription}&controlled=${item.isControlledSubstance}`,
      });
    } else {
      navigate({
        search: `rx=${femaleHormoneRxTypes[name]?.link}&id=${item.id}&covered=${item.isCoveredUnderSubscription}&controlled=${item.isControlledSubstance}`,
      });
    }
  };

  function getColor(item: string) {
    if (gender === Gender.Male) {
      return maleHormoneRxTypes[normalizeString(item)]?.link === rx
        ? 'primary'
        : 'secondary';
    }

    return femaleHormoneRxTypes[normalizeString(item)]?.link === rx
      ? 'primary'
      : 'secondary';
  }

  const showEmptyContainer = useMemo(
    () => Number(getHormonesQuery?.data?.data?.length) < 10,
    [getHormonesQuery?.data?.data]
  );

  return (
    <div className="w-full ">
      {isPrescribeActive && (
        <div className="  grid grid-cols-10 pt-0 ">
          <div className="  col-span-3  grid pr-3">
            <div className="">
              <Typography
                variant="h3"
                color="textMuted"
                className="justify-center bg-background-main py-3 ">
                Select Rx to Prescribe
              </Typography>

              <div className="scrollbar-hide mt-1 flex h-[40rem] cursor-pointer  flex-col overflow-auto rounded-b">
                {getHormonesQuery.isLoading ? (
                  <ContainerSkeleton className="flex h-full  flex-col  ">
                    {[...Array(10)].map(() => (
                      <div className="mb-[1px] flex  grow items-center bg-background-main pl-6  ">
                        <div className="h-4 w-1/2 animate-pulse rounded bg-background-light " />
                      </div>
                    ))}
                  </ContainerSkeleton>
                ) : (
                  <div className="flex h-full flex-col">
                    {getHormonesQuery?.data?.data.map((item) => (
                      <Button
                        key={item.name}
                        color={getColor(item.name)}
                        className="mb-[1px] max-h-[4.4rem] min-h-[4rem] !justify-start rounded-none py-4   pl-6 text-md shadow-sm"
                        onClick={() => rxTypeClickHandler(item)}>
                        {item?.name}
                      </Button>
                    ))}
                    {showEmptyContainer && (
                      <div className="grow bg-background-light"> </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-7 h-[43rem] rounded-b  bg-background-main ">
            {rx ? (
              <PrescriptionDetailsNew addNewPrescription={addNewPrescription} />
            ) : (
              <div className="-mt-6 flex h-full items-center justify-center">
                <Typography variant="h4" color="textMuted">
                  Please select a hormone
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="col-span-12 text-black dark:text-white">
        <PrescribedHormonesNew
          titrationType={titrationType as TitrationConfigType}
          prescribedHormones={prescribedHormones}
          removePrescribedItem={removePrescribedItem}
          isPrescribeActive={isPrescribeActive}
          setIsPrescribeActive={setIsPrescribeActive}
          isLoading={
            titrationType ? getTitrationRxConfigQuery.isLoading : false
          }
          buttonLoading={prescribeMedicationMutation?.isLoading}
          excludedHormones={excludedHormones}
        />
      </div>

      <ConfirmationModal
        onCancel={() => {
          setShowPrescriptionConfirmation(false);
          queryClient.refetchQueries(
            QueryKeys.PatientDiagnosisCode.listing({ id: patientId })
          );
          navigate({
            pathname: '../../../overview',
          });
        }}
        infoButtonCancelText="Close"
        wider
        isModalOpen={showPrescriptionConfirmation}
        closeModal={() => {
          // setShowPrescriptionConfirmation(false);
        }}>
        <Typography variant="subtitle1">
          {`Your prescription(s) for ${`${firstName} ${lastName}`}  have been submitted. Shorty you will receive a notification to log into NewCrop and confirm prescriptions (This extra step is temporary. As soon as we move out of the beta test stage, there will be no more to do! We anticipate three weeks to completion).`}
        </Typography>
        <Typography color="error" className="mt-2" variant="subtitle1">
          IMPORTANT NOTE: Confirming prescriptions is a required step. Only
          after you confirm will the pharmacy begin to make up your compounded
          prescriptions.
        </Typography>
      </ConfirmationModal>
      <Suspense>
        <ConfirmationModal
          isModalOpen={openWarningPopup.state}
          infoContent={
            <div>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.newHormoneName}{' '}
              </Typography>
              <Typography variant="h4" className="!inline">
                could not be added because{' '}
              </Typography>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.name}
              </Typography>{' '}
              <Typography variant="h4" className="!inline">
                is already there from{' '}
              </Typography>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.family}
              </Typography>{' '}
              <Typography variant="h4" className="!inline">
                family. Please remove it to add this one.{' '}
              </Typography>
            </div>
          }
          closeModal={() => {
            setOpenWarningPopup({ ...openWarningPopup, state: false });
            setTimeout(
              () => setOpenWarningPopup({ state: false, details: undefined }),
              300
            );
          }}
        />
      </Suspense>
    </div>
  );
}
