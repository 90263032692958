import React, { Dispatch, useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';
import { PrescribedObjectType } from 'types/prescriptionTypes';

type PrescriptionsBreakdownProps = {
  prescriptions: PrescribedObjectType[];
  isConcierge: boolean;
  patientId: string;
  billingCycle?: string;
  setBillingCycle: Dispatch<React.SetStateAction<string | undefined>>;
  deliveryMethod?: string;
  setDeliveryMethod: Dispatch<React.SetStateAction<string | undefined>>;
};
function PrescriptionInfo({
  prescription,
}: {
  prescription: PrescribedObjectType;
}) {
  return (
    <div className={classNames('mt-6 flex flex-col')}>
      {' '}
      <Typography className="mb-1" variant="h4">
        {prescription?.displayName}
      </Typography>
      <div className="mt-1 flex gap-32 ">
        <Typography variant="h5" className="min-w-[15rem]" color="primary">
          <span className="min-w-[4rem]">Quantity</span>: {prescription?.dtd}{' '}
          {prescription?.dtdUnit}
        </Typography>
        <Typography
          className="flex whitespace-nowrap"
          variant="h5"
          color="primary">
          <span
            className={classNames('min-w-[4rem]', {
              '!min-w-[6rem]': !!prescription?.ratio,
            })}>
            Strength
          </span>
          : {prescription?.strength} {prescription?.strengthUnit}
        </Typography>
      </div>
      <div className="mt-2 flex gap-32">
        <Typography variant="h5" className="min-w-[15rem]" color="primary">
          <span className="min-w-[4rem]">Dose</span>: {prescription?.dose}{' '}
          {prescription?.doseUnit}
        </Typography>
        {!!prescription?.ratio && (
          <Typography variant="h5" color="primary">
            <span className="min-w-[6rem]">Ratio(E3:E2)</span>:{' '}
            {prescription?.ratio}
          </Typography>
        )}
      </div>
      <Typography
        variant="h5"
        className="wrap mt-2 !flex !items-start"
        color="primary">
        <span className="min-w-[4rem]">SIG</span>: {'  '}
        <span className="ml-1 ">{prescription?.sig}</span>
      </Typography>
    </div>
  );
}
export function PrescriptionsBreakdown({
  prescriptions,
  isConcierge,
  patientId,
  billingCycle,
  deliveryMethod,
  setBillingCycle,
  setDeliveryMethod,
}: PrescriptionsBreakdownProps) {
  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(patientId as string),
    () => PatientService.getPatientDetails(patientId || ''),
    { enabled: !!patientId }
  );

  const onShippingTypeChange = (value: string | boolean) => {
    if (value === deliveryMethod) {
      setDeliveryMethod(undefined);
    } else {
      setDeliveryMethod(value as string);
    }
  };

  const onBillingCycleChange = (value: string | boolean) => {
    if (value === billingCycle) {
      setBillingCycle(undefined);
    } else {
      setBillingCycle(value as string);
    }
  };
  const showBillingCycle = useCallback(() => {
    if (
      getPatientDetailsQuery.data?.data?.patientCompletedVisits === 0 ||
      getPatientDetailsQuery.data?.data?.patientCompletedVisits === null
    ) {
      return true;
    }

    return false;
  }, [getPatientDetailsQuery.data?.data?.patientCompletedVisits]);

  const showShippingType = useCallback(() => {
    if (getPatientDetailsQuery.data?.data?.practice.isShippingCostCovered) {
      return true;
    }

    return false;
  }, [getPatientDetailsQuery.data?.data?.practice.isShippingCostCovered]);
  function showCoveredUnderSubscription() {
    return prescriptions?.some(
      (p) =>
        p.coveredUnderSubscription?.toString().toLocaleLowerCase() === 'true'
    );
  }

  function showCoveredNotUnderSubscription() {
    return prescriptions?.some(
      (p) =>
        p.coveredUnderSubscription?.toString().toLocaleLowerCase() === 'false'
    );
  }

  return (
    <div className="flex w-full   flex-col  rounded bg-background-main p-6">
      <Typography variant="h4">Prescriptions</Typography>

      {isConcierge && (
        <div className="flex flex-col ">
          {showBillingCycle() && (
            <div className=" mt-6 flex flex-col">
              <Typography variant="h5">Select Billing Cycle</Typography>
              <div className="mt-2 flex justify-between">
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    id="annual"
                    className="p-3 pr-0"
                    onChange={onBillingCycleChange}
                    label="Annual"
                    checked={billingCycle === 'annual'}
                  />
                </div>
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light  ">
                  <RadioButton
                    className="p-3 pr-0"
                    id="monthly"
                    onChange={onBillingCycleChange}
                    label="Monthly"
                    checked={billingCycle === 'monthly'}
                  />
                </div>
              </div>
            </div>
          )}
          {showShippingType() && (
            <div className="mt-4 flex flex-col">
              <Typography variant="h5">
                To comply with federal and state regulations, your prescriptions
                will require a signature upon delivery.
              </Typography>
              <div className="mt-4 flex  flex-col gap-4">
                <div className=" w-full cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    fullWidth
                    className="w-full  p-3 pr-0"
                    id="expedited"
                    onChange={onShippingTypeChange}
                    label="Someone can be home to sign for and accept shipment (FedEx 2nd day. Indirect signature)."
                    checked={deliveryMethod === 'expedited'}
                  />
                </div>{' '}
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    fullWidth
                    id="standard"
                    className="p-3 pr-0"
                    onChange={onShippingTypeChange}
                    label="The patient can be home to sign for and accept shipment (USPS 2nd day. Patient-only signature)."
                    checked={deliveryMethod === 'standard'}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showCoveredUnderSubscription() && (
        <div className="mt-6 rounded bg-background-light p-4">
          <Typography variant="h4">Rx(s) covered under subscription</Typography>
          {prescriptions
            ?.filter(
              (p) =>
                p.coveredUnderSubscription?.toString().toLocaleLowerCase() ===
                'true'
            )
            ?.map((p) => (
              <PrescriptionInfo prescription={p} />
            ))}
        </div>
      )}
      {showCoveredNotUnderSubscription() && (
        <div className="mt-6 rounded bg-background-light p-4">
          <Typography variant="h4">
            {' '}
            Rx(s){' '}
            <Typography className="mx-1" color="error" variant="h4">
              NOT
            </Typography>{' '}
            covered under subscription
          </Typography>
          {prescriptions
            ?.filter(
              (p) =>
                p.coveredUnderSubscription?.toString().toLocaleLowerCase() ===
                'false'
            )
            ?.map((p) => (
              <PrescriptionInfo prescription={p} />
            ))}
        </div>
      )}
    </div>
  );
}
